<template>
    <div>
        <div class="modal fade" id="QualityControlItemModal" tabindex="-1" aria-labelledby="QualityControlItemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content border border-primary">
                    <div class="modal-header">
                        <h4 id="QualityControlItemModalLabel" class="font-weight-bold">Add Waste/Rejected Item</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">                        
                        <div class="col-xs-6 text-light pb-2">
                            <button id="add" class="btn btn-primary"
                                @click="addItemClick()">
                                <i class="fa fa-plus"></i> Add Item
                            </button>
                        </div>

                        <datasource ref="QcItemDataSource" :data="this.QcItemGridData" :page-size="10" :schema-model-fields="this.QcItemGridDataSchemaModel"/>

                        <kendo-grid ref="gridQcItem"
                            :data-source-ref="'QcItemDataSource'"
                            :editable="true"
                            :pageable="true"
                            :resizable="true"
                            :filterable="true"
                            :sortable="true"
                            :cellClose="cellClose"
                        >
                            <kendo-grid-column  :field="'item_id'"
                                                :title="'Item Id'"
                                                :width="200"
                                                :hidden="true"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'product_code'"
                                                :title="'Kode Item'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'item_name'"
                                                :title="'Nama Item'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'base_uom'"
                                                :title="'Satuan Dasar'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'quantity'"
                                                :title="'Quantity'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                            <kendo-grid-column  :field="'secondary_uom'"
                                                :title="'Satuan Konversi'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'secondary_conv_amount'"
                                                :title="'Nilai Konversi'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'secondary_quantity'"
                                                :title="'Quantity Konversi'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'mutation_type'"
                                                :title="'Tipe Mutasi'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :editor= "ItemTypeDropdownEditor"></kendo-grid-column>
                            
                            <kendo-grid-column  :title="'&nbsp;'"
                                                :attributes="{ class: 'k-text-center' }"
                                                :command="['destroy']"
                                                :width="100"></kendo-grid-column>
                        </kendo-grid>                        
                        <br>
                        
                        <item-form ref="ItemFormModal" :saveItemGrid="saveItemGrid"/>

                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';

import qualityControlService from '../../RequestMaterial/Script/RequestMaterialServices.js';
import itemForm from '../Component/ItemListForm.vue';

export default {
    name: 'QualityControlItemForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'item-form' : itemForm,
    },
    props: ['saveQualityControlItemGrid'],
    data: function () {
        return {
            Error : 0,
            ProductionMutationId: "",
            ItemTypeList: [],
            QcItemGridData:[],
            QcItemGridDataSchemaModel: {
                item_id: { type: "string", editable: false},
                product_code: { type: "string", editable: false},
                item_name: { type: "string", editable: false},
                base_uom: { type: "string", editable: false},
                quantity: { type: "number", editable: true, validation: { min:0 }},
                secondary_uom: { type: "string", editable: false},
                secondary_conv_amount: { type: "string", editable: false},
                secondary_quantity: { type: "number", editable: true, validation: { min:0 }},
                mutation_type: { type: "string", editable: true},
            },
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted() {
        this.ItemTypeList = [
            { label: 'Waste QC', value: 'Waste QC' },
            { label: 'Rejected QC', value: 'Rejected QC' },
        ]
    },
    methods: {
        addClick(id){
            this.ProductionMutationId = id;            
            this.QcItemGridData = [];

            window.$('#QualityControlItemModal').modal('show');
        },
        addItemClick(){
            this.$refs.ItemFormModal.addClick();
        },
        ItemTypeDropdownEditor(container, options) {
            $('<input data-bind="value:' + options.field + '" />')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    dataSource: this.ItemTypeList,
                    close: function(e) {
                        var selectedValue = this.value();
                        options.model.set("mutation_type", selectedValue);
                    }
                });
        },
        cellClose(e) {
            var gridData = this.$refs.gridQcItem.kendoWidget().dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridData, 'QualityControl-WasteRejectedItemGrid');

            var dataItem = e.sender.dataItem($(e.container).parent());            
            var changesItem = gridData.filter(obj => { return obj.item_id == dataItem.item_id });

            if (changesItem[0].dirtyFields.hasOwnProperty('quantity')) {
                changesItem[0].secondary_quantity = (changesItem[0].quantity * changesItem[0].secondary_conv_amount);
                changesItem[0].dirtyFields = {};
            }
            
            if (changesItem[0].dirtyFields.hasOwnProperty('secondary_quantity')) {
                changesItem[0].quantity = (changesItem[0].secondary_quantity / changesItem[0].secondary_conv_amount);
                changesItem[0].dirtyFields = {};
            }
            
            var changeItemData = this.$globalfunc.objectToArrayConverter(changesItem, 'QualityControl-WasteRejectedItemGrid');

            //replace same item id after the changes
            itemData = itemData.map(obj => changeItemData.find(o => o.item_id === obj.item_id) || obj);
            this.QcItemGridData = itemData;
        },
        saveItemGrid(newData) {
            var gridData = this.$refs.gridQcItem.kendoWidget().dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridData, 'QualityControl-WasteRejectedItemGrid');

            var duplicate = false;
            if (newData.length > 0) {
                duplicate = qualityControlService.duplicateItemChecker(itemData, newData[0]);
            }

            if (duplicate) {
                this.$swal("Error", "Item sudah ada", "error");
            }
            else {
                itemData = itemData.concat(newData);
                this.QcItemGridData = itemData;
            }
        },
        async inputValidation() {
            this.Error = 0;
            
            var grid = this.$refs.gridQcItem.kendoWidget().dataSource._data;
            if (grid.length > 0) {
                // validation qty
                for (let i = 0; i < grid.length; i++){
                    if (grid[i].quantity == null || grid[i].quantity == 0) {
                        this.$swal("Error", "Quantity pada item yang dipilih tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                    else if (grid[i].mutation_type == null) {
                        this.$swal("Error", "Tipe Mutasi pada item yang dipilih tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                }                
            }
            else
            {
                this.$swal("Error", "Dimohon untuk memilih item terlebih dahulu", "error");
            }
        },
        saveClick(){
            this.inputValidation();
                        
            if (this.Error == 0) {
                // this.$loading(true);

                var grid = this.$refs.gridQcItem.kendoWidget().dataSource._data;
                var data = qualityControlService.itemDataObject(grid, this.ProductionMutationId);
                var ProductionMutationItemArray = this.$globalfunc.objectToArrayConverter(data, "RequestMaterial-SaveMutationItemGrid");

                const variables = {
                    id: parseInt(this.ProductionMutationId),
                    data : ProductionMutationItemArray
                }
                
                qualityControlService.calculateQualityControlWeightValidation(variables).then(res => {
                    this.$loading(false);
                    this.$swal("Info", res, "success").then(
                        this.$props.saveQualityControlItemGrid(data)
                    );                    
                    window.$('#QualityControlItemModal').modal('hide');
                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
    },
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>