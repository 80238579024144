<template>
    <div>
        <div class="modal fade" id="ItemFormModal" tabindex="-1" aria-labelledby="ItemFormModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content border border-primary">
                    <div class="modal-header">
                        <h4 id="ItemFormModalLabel" class="font-weight-bold">Add Item</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :columns="columns"
                            :resizable="true"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :selectable="true"
                            :editable="true"
                        >
                        </kendo-grid>
                        
                        <br>
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import { template } from '@babel/core';

import qualityControlService from '../../RequestMaterial/Script/RequestMaterialServices.js';

export default {
    name: 'ItemDataForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['saveItemGrid'],
    data: function () {
        return {
            ItemTypeList: [],
            dataSource: [],
            columns: [
                { field: 'item_id', title: "ID Item", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, hidden: true },
                { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'item_name', title: "Nama Item", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'type_detail_name', title: "Tipe Detail", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                            }
                            return { 
                                query: qualityControlService.getQualityControlItem(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetQualityControlItem.quality_control_item == null) {
                            return [];
                        }
                        else {
                            return response.data.GetQualityControlItem.quality_control_item;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetQualityControlItem.quality_control_item == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetQualityControlItem.total;
                        }
                    },                    
                    model: {
                        fields: {
                            item_id: { type: "string", editable: false },
                            product_code: { type: "string", editable: false },
                            item_name: { type: "string", editable: false },
                            type_detail_name: { type: "string", editable: false },
                        }
                    }
                },
            });
            window.$('#ItemFormModal').modal('show');
        },
        saveClick(){
            var grid = this.$refs.grid.kendoWidget();
            var selectedItem = grid.dataItem(grid.select());

            if (selectedItem != null) {
                var data = qualityControlService.stagingItemDataObject(selectedItem);
                this.$props.saveItemGrid(data)
            }
            else
            {
                this.$swal("Error", "Dimohon untuk memilih item terlebih dahulu", "error");
            }
        },
    },
}
</script>
